export interface RevForcedAlignment {
    media_download_status: 'in_progress' | 'completed' | 'failed'| 'not_started'
    transcript_text_file_download_status: 'in_progress' | 'completed' | 'failed' | 'not_started'
    rev_result_download_status: 'in_progress' | 'completed' | 'failed' | 'not_started'
    is_media_url_public: boolean
    is_transcript_text_file_url_public: boolean
    last_job_id?: string
    last_job_requested_on: string
    status?: RevForcedAlignmentStatus
    jobs_ids: string[]
    last_job_completed_on?: string
    error_created_on?: string

}

export enum RevForcedAlignmentStatus {
    IN_PROGRESS = 'in_progress',
    COMPLETED = 'completed',
    NOT_STARTED = 'not_started',
    FAILED_INVALID_PARAMS_ERROR = 'failed_invalid_params_error',
    FAILED_AUTHENTICATION_ERROR = 'failed_authentication_error',
    FAILED_PAYLOAD_TOO_LARGE_ERROR = 'failed_payload_too_large_error',
}
